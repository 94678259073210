import './index.scss'
import Swiper from 'swiper/swiper-bundle.min.js'
// 鍒嗗睆
import '../../assets/styles/jquery.fullpage.min.css'
import '../../assets/scripts/jquery.fullpage.min.js'
$("#nav1").addClass("active");

var interleaveOffset = 0.5; //瑙嗗樊姣斿€糪r
var swiperOptions = {
    loop: true,
    speed: 1000,
    grabCursor: true,
    watchSlidesProgress: true,
    mousewheelControl: true,
    keyboardControl: true,
    autoplay: {
        delay: 5000,
        stopOnLastSlide: false,
        disableOnInteraction: false,
    },
    pagination: {
        el: "#banner-swiper .swiper-pagination",
        clickable: true,
    },
    on: {
        progress: function (swiper) {
            for (var i = 0; i < swiper.slides.length; i++) {
                var slideProgress = swiper.slides[i].progress;
                var innerOffset = swiper.width * interleaveOffset;
                var innerTranslate = slideProgress * innerOffset;
                swiper.slides[i].querySelector("#banner-swiper .swiper-slide img").style.transform =
                    "translate3d(" + innerTranslate + "px, 0, 0)";
            }
        },
        touchStart: function (swiper) {
            for (var i = 0; i < swiper.slides.length; i++) {
                swiper.slides[i].style.transition = "";
            }
        },
        setTransition: function (swiper, speed) {
            for (var i = 0; i < swiper.slides.length; i++) {
                swiper.slides[i].style.transition = speed + "ms";
                swiper.slides[i].querySelector("#banner-swiper .swiper-slide img").style.transition =
                    speed + "ms";
            }
        },
    },
    
};
var swiper = new Swiper("#banner-swiper", swiperOptions);
// 鍏充簬鎴戜滑鏁板瓧婊氬姩

//绐楀彛鏄剧ず鎵嶅姞杞絓r
var wrapTop = $(".page2_bg").offset().top;
console.log(wrapTop);
var istrue = true;
$(window).on("scroll",
function() {
    var s = $(window).scrollTop();
    if (s > wrapTop - 500 && istrue) {
        $(".timer").each(count);
        function count(a) {
            var b = $(this);
            a = $.extend({},
            a || {},
            b.data("countToOptions") || {});
            b.countTo(a)
        };
        istrue = false;
    };
})
//璁剧疆璁℃暟
$.fn.countTo = function (options) {
    options = options || {};
    return $(this).each(function () {
        //褰撳墠鍏冪礌鐨勯€夐」
        var settings = $.extend({}, $.fn.countTo.defaults, {
            from:            $(this).data('from'),
            to:              $(this).data('to'),
            speed:           $(this).data('speed'),
            refreshInterval: $(this).data('refresh-interval'),
            decimals:        $(this).data('decimals')
        }, options);
        //鏇存柊鍊糪r
        var loops = Math.ceil(settings.speed / settings.refreshInterval),
            increment = (settings.to - settings.from) / loops;
        //鏇存敼搴旂敤鍜屽彉閲廫r
        var self = this,
        $self = $(this),
        loopCount = 0,
        value = settings.from,
        data = $self.data('countTo') || {};
        $self.data('countTo', data);
        //濡傛灉鏈夐棿鏂紝鎵惧埌骞舵竻闄r
        if (data.interval) {
            clearInterval(data.interval);
        };
        data.interval = setInterval(updateTimer, settings.refreshInterval);
        //鍒濆鍖栬捣濮嬪€糪r
        render(value);
        function updateTimer() {
            value += increment;
            loopCount++;
            render(value);
            if (typeof(settings.onUpdate) == 'function') {
                settings.onUpdate.call(self, value);
            }
            if (loopCount >= loops) {
                //绉诲嚭闂撮殧
                $self.removeData('countTo');
                clearInterval(data.interval);
                value = settings.to;
                if (typeof(settings.onComplete) == 'function') {
                    settings.onComplete.call(self, value);
                }
            }
        }
        function render(value) {
            var formattedValue = settings.formatter.call(self, value, settings);
            $self.html(formattedValue);
        }
        });
    };
    $.fn.countTo.defaults={
        from:0,               //鏁板瓧寮€濮嬬殑鍊糪r
        to:0,                 //鏁板瓧缁撴潫鐨勫€糪r
        speed:1000,           //璁剧疆姝ラ暱鐨勬椂闂碶r
        refreshInterval:100,  //闅旈棿鍊糪r
        decimals:0,           //鏄剧ず灏忎綅鏁癨r
        formatter: formatter, //娓叉煋涔嬪墠鏍煎紡鍖朶r
        onUpdate:null,        //姣忔鏇存柊鍓嶇殑鍥炶皟鏂规硶
        onComplete:null       //瀹屾垚鏇存柊鐨勫洖璋冩柟娉昞r
    };
    function formatter(value, settings){
        return value.toFixed(settings.decimals);
    }
    //鑷畾涔夋牸寮廫r
    $('#count-number').data('countToOptions',{
        formmatter:function(value, options){
            return value.toFixed(options.decimals).replace(/\B(?=(?:\d{3})+(?!\d))/g, ',');
        }
    });
    //瀹氭椂鍣╘r
    $('.timer').each(count);
    function count(options){
        var $this=$(this);
        options=$.extend({}, options||{}, $this.data('countToOptions')||{});
        $this.countTo(options);
    }

    $(function(){
        $('#dowebok').fullpage({
            navigation:true,
            verticalCentered:false,
            css3: false,
            scrollingSpeed:800,
            slidesNavigation:false,
    
            anchors: ['page1', 'page2','page3','page4','page5','page6'],
            menu: '#menu',
            autoScrolling: true,
            //anchors: ['hero', 'services', 'cases', 'news','about','contact','footer']
            
            afterLoad: function(anchorLink, index)
            {
                if(index == 1)
                {
                    $("header").removeClass("head1");
                }
                if(index == 2)
                {
                    $("header").addClass("head1");
                    
                    $(".timer").each(count);
                    function count(a) {
                        var b = $(this);
                        a = $.extend({},
                        a || {},
                        b.data("countToOptions") || {});
                        b.countTo(a)
                    };
                    istrue = false;
                }
                if(index == 3)
                {		
                    $("header").addClass("head1");
                }
                if(index == 4)
                {		
                    $("header").addClass("head1");
                }
                if(index == 5)
                {		
                    $("header").addClass("head1");
                }
                if(index == 6)
                {
                    $("header").addClass("head1");
                }
                if(index == 7)
                {
                    $("header").addClass("head1");
                }
            },
        
    
        });
        $(window).resize(function(){
            autoScrolling();
        });
        /*鎵嬫満涓婁笉婊氬姩*/
        function autoScrolling(){
            var $ww = $(window).width();
            if($ww < 1024){
                $.fn.fullpage.setAutoScrolling(false);
                $.fn.fullpage.setFitToSection(false);
            } else {
                $.fn.fullpage.setAutoScrolling(true);
            }
        }
        autoScrolling();
    });
    

// 瑙嗛
$(".play").click(function () {
    var video = document.getElementById('myVideo');
    if (video.paused) { //濡傛灉宸叉殏鍋滃垯鎾斁
        $('.play').hide();
        video.play(); //鎾斁鎺у埗
    } else { // 宸叉挱鏀剧偣鍑诲垯鏆傚仠
        $('.play').show();
        video.pause(); //鏆傚仠鎺у埗
    }
})
// 涓氬姟棰嗗煙
$(".section2 ul li").hover(function() {
    $(".section2 ul li").removeClass("active")
    $(".section2 ul li").removeClass("active1")
    $(this).addClass("active").siblings().addClass("active1");
})
$(".section2").mouseleave(function() {
    $(".section2 ul li").removeClass("active")
    $(".section2 ul li").removeClass("active1")
})
// 鏍稿績绔炰簤鍔沑r
$(".section3 ul li").eq(2).addClass("active")
$(".core-text .text").eq(2).addClass("active")
$(".section3 ul li").hover(function () {
    let index = $(this).index();
    $(this).addClass("active").siblings().removeClass("active");
    $(".core-text .text").eq(index).addClass("active").siblings().removeClass("active");
})